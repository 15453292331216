import * as React from "react"
import PropTypes from "prop-types"

const Accesibilidad = () => (
  <div
    className="tamano_header_accesibilidad"
    style={{
      position: "fixed",
      maxWidth: "100%",
      height: "2rem",
      boxSizing: "border-box",
      display: "flex",
      backgroundColor: "#898a8b",
      zIndex: "999999999",
      width: "100%",
    }}
  >
    <div
      className="alinear_accesibilidad"
      style={{
        position: "relative",
        left: "70%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxSizing: "border-box",
      }}
    >
      <div
        className="alinear_encabezado_accesibilidad"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "stretch",
          margin: "0px",
          padding: "0px",
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            position: "relative",
            margin: "0px",
            padding: "0px",
            display: "flex",
            height: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="alinear_ancabezados_accesibilidad"
            style={{
              position: "relative",
              margin: "0px",
              padding: "0px",
              height: "100%",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              paddingLeft: "16px",
              paddingRight: "16px",
              right: "18%",
              justifyContent: "flex-end",
              fontSize: "13px",
            }}
          >
            <p
              className="tamano_letra_contraste_movil text-poppins-regular"
              style={{ marginRight: "0.5rem", color: "white" }}
            >
              MODO <strong>OSCURO</strong>
            </p>

            <div className="theme-switch-wrapper">
              <label className="theme-switch" htmlFor="chkAccesibilidad">
                <input type="checkbox" id="chkAccesibilidad" tabIndex="1" />
                <div
                  className="slider round"
                  tabIndex="1"
                  id="sliderChkAccesibilidad"
                ></div>
              </label>
            </div>
          </div>

          <div
            className="alinear_ancabezados_accesibilidad"
            style={{
              position: "relative",
              margin: "0px",
              padding: "0px",
              height: "100%",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              paddingLeft: "16px",
              paddingRight: "16px",
              right: "18%",
              fontSize: "13px",
            }}
          >
            <p
              className="tamano_letra_accesibilidad_movil text-poppins-regular"
              style={{ marginRight: "0.5rem", color: "white" }}
            >
              TAMAÑO DE LETRA
            </p>

            <div className="font-controls">
              <button
                className="font-control disminuir"
                id="font-up"
                style={{
                  position: "relative",
                  top: "2px",
                  width: "18px",
                  height: "18px",
                  display: "flex",
                  margin: "0px",
                  padding: "0px",
                  border: "none",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  minWidth: "initial !important",
                }}
                tabIndex="1"
              >
                <i
                  className="fa fa-minus-circle"
                  aria-hidden="true"
                  style={{ color: "#c5cc0c" }}
                ></i>
              </button>
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  top: "2px",
                  margin: "0px 7px",
                  padding: "0px",
                  cursor: "pointer",
                  fontFamily: "Barlow-Bold",
                  fontSize: "13px",
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontWeight: "normal",
                  lineHeight: "normal",
                }}
              >
                <p className="text-poppins-regular">A</p>
              </div>
              <button
                className="font-control aumentar"
                id="font-up"
                style={{
                  position: "relative",
                  top: "2px",
                  width: "18px",
                  height: "18px",
                  display: "flex",
                  margin: "0px",
                  padding: "0px",
                  border: "none",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  minWidth: "initial !important",
                }}
                tabIndex="1"
              >
                <i
                  className="fa fa-plus-circle"
                  aria-hidden="true"
                  style={{ color: "#c5cc0c" }}
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

Accesibilidad.propTypes = {
  siteTitle: PropTypes.string,
}

Accesibilidad.defaultProps = {
  siteTitle: ``,
}

export default Accesibilidad
